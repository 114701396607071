import {ChangeEvent, RefObject} from "react";
import {checkWebsite, sendAcceptPaymentsForm, sendForTestingForm} from "../../api";
import {ForTestingInputs} from "../../@types/forTestingFormResources.ts";
import {callVerification} from "../../../public/js/fancybox/fancybox";
import {RegistrationStatusEnum} from "../../enum/registration/RegistrationStatusEnum.ts";
import i18next, {t} from "i18next";
import ReCAPTCHA from "react-google-recaptcha";
import {languageToIso3} from "../language/languageHelper.ts";
import {AcceptPaymentsInputs} from "../../@types/acceptPaymentsFormResources.ts";
import IBAN from "iban";
import {log} from "../../logger/log.ts";
import * as EmailValidator from 'email-validator';

const checkWebsiteIdErrorCodes = ['WEBSITE_IS_NOT_FOUND', 'INVALID_WEBSITE_ID', 'WEBSITE_IS_NOT_ALLOWED_TO_REGISTER'];
const unclearableInputs = [
    'web_protocol',
    'website_phone_number_prefix',
    'phone_number_prefix',
    'individual_merchant_phone_number_prefix',
    'company_merchant_phone_number_prefix'
];
const bankAccountInputs = [
    'company_agreement_bank_account',
    'individual_agreement_bank_account'
];
const nestedInputs = [
    'website_url',
    'website_phone_number',
    'individual_merchant_phone_number',
    'company_merchant_phone_number'
];

export const handlePhonePrefix = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value.startsWith('+')) {
        event.target.value = '+' + event.target.value;
    }

    event.target.value = event.target.value.replace(/\s/g, "").replace(/[^\d+]/g,'');
}

export const handleNumericField = (event: ChangeEvent<HTMLInputElement>) => {
    event.target.value = event.target.value.replace(/\s/g, "").replace(/\D/g,'');
}

export const removeSpaces = (event: ChangeEvent<HTMLInputElement>) => {
    event.target.value = event.target.value.replace(/\s/g, "");
}

export const handleWebsiteId = async (input: HTMLInputElement) => {
    const language = languageToIso3(i18next.language);
    const form = document.getElementById('accept-payments-form') as HTMLFormElement;
    const websiteUrlInput = document.getElementById('website_check_url') as HTMLInputElement;

    clearWebsiteIdError(input);
    websiteUrlInput.value = '';

    if (input.value.length === 10) {
        try {
            const response = await checkWebsite({website_id: input.value, language: language});

            if (response.data.response.result.website_url !== null) {
                websiteUrlInput.value = response.data.response.result.url;
            }
        } catch (error: any) {
            form?.scrollIntoView();
            handleWebsiteIdErrors(error.response.data.response.errors[0]);
        }
    }
}

export const validateFormFields = (form: HTMLFormElement|null) => {
    let errors = 0;
    clearPreviousErrors();

    if (form) {
        const formElements = Array.from(form.elements).filter((element: Element) =>
            element instanceof HTMLInputElement || element instanceof HTMLTextAreaElement
        );

        formElements.forEach((element) => {
            const errorBlock = document.createElement('div');
            errorBlock.className = 'error-block';
            errorBlock.innerHTML = `${t('fill_the_field')}`;

            if (element instanceof HTMLInputElement) {
                const input = element as HTMLInputElement;

                if (bankAccountInputs.includes(input.id) && !IBAN.isValid(input.value)) {
                    errorBlock.innerHTML = `${t('iban_is_not_valid')}`;
                    input.parentElement?.classList.add('has-error');
                    input.parentElement?.appendChild(errorBlock);

                    return errors++;
                }

                if (input.id === 'website_check_url') {
                    return;
                }

                if (input.type === 'checkbox' && !input.checked) {
                    errorBlock.innerHTML = `${t('please_check_this_box')}`;
                }

                if (input.id === 'g-recaptcha-response') {
                    errorBlock.innerHTML = `${t('check_captcha')}`;
                }

                if (input.type === 'email' && !EmailValidator.validate(input.value)) {
                    errorBlock.innerHTML = `${t('wrong_email_format')}`;
                }

                if (
                    input.value === ''
                    || (input.type === 'checkbox' && !input.checked)
                    || (input.type === 'email' && !EmailValidator.validate(input.value))
                ) {
                    if (nestedInputs.includes(input.id) || input.type === 'checkbox') {
                        input.parentElement?.parentElement?.classList.add('has-error');
                        input.parentElement?.parentElement?.appendChild(errorBlock);

                        return errors++;
                    }

                    input.parentElement?.classList.add('has-error');
                    input.parentElement?.appendChild(errorBlock);

                    return errors++;
                }
            } else if (element instanceof HTMLTextAreaElement) {
                const textarea = element as HTMLTextAreaElement;

                if (textarea.value === '') {
                    textarea.parentElement?.classList.add('has-error');
                    textarea.parentElement?.appendChild(errorBlock);

                    return errors++;
                }
            }
        });
    }

    if (errors > 0 && form) {
        const errorBlock = document.createElement('div');
        errorBlock.className = 'alert alert-danger top-error fw-semibold';
        errorBlock.innerHTML = `${t('wrong_filled_form')}`;

        form.prepend(errorBlock);
        form.scrollIntoView();
    }

    return errors;
}

const handleWebsiteIdErrors = (error: any) => {
    const websiteIdInput = document.getElementById('website_id');
    const errorMessage = error.message;
    const errorBlock = document.createElement('div');
    errorBlock.innerHTML = errorMessage;

    if (websiteIdInput) {
        errorBlock.className = 'error-block';
        websiteIdInput.parentElement?.parentElement?.classList.add('has-error');
        websiteIdInput.parentElement?.appendChild(errorBlock);
    }
}

const handleRegistrationErrors = (error: any, form: HTMLFormElement|null) => {
    const destructuredError = error.response?.data?.response?.errors[0];

    const websiteIdInput = document.getElementById('website_id');
    const errorBlock = document.createElement('div');

    if (destructuredError === undefined) {
        errorBlock.className = 'alert alert-danger top-error fw-semibold';
        errorBlock.innerHTML = `${t('something_went_wrong')}`;
        log.error(error);
        clearErrorsOnClick(form, true);

        return form?.prepend(errorBlock);
    }

    const errorMessage = destructuredError.message;
    errorBlock.className = 'error-block';
    errorBlock.innerHTML = errorMessage;

    if (destructuredError.data?.parameter !== undefined) {
        const input = document.getElementById(destructuredError.data.parameter);

        if (input) {
            input.parentElement?.parentElement?.classList.add('has-error');
            input.parentElement?.parentElement?.appendChild(errorBlock);
        }

        return;
    }

    if (checkWebsiteIdErrorCodes.includes(destructuredError.code)) {
        websiteIdInput?.parentElement?.parentElement?.classList.add('has-error');
        websiteIdInput?.parentElement?.parentElement?.appendChild(errorBlock);
        errorBlock.className = 'alert alert-danger top-error fw-semibold';
        form?.prepend(errorBlock);

        return;
    }

    if (destructuredError.code === 'WEBSITE_IS_NOT_ALLOWED_TO_REGISTER') {
        errorBlock.className = 'alert alert-danger top-error fw-semibold';
        errorBlock.innerHTML = `${t('website_is_not_allowed_to_register')}`;

        return form?.prepend(errorBlock);
    }

    errorBlock.className = 'alert alert-danger top-error fw-semibold';
    errorBlock.innerHTML = `${t('something_went_wrong')}`;

    // log unhandled error
    log.error(destructuredError);

    clearErrorsOnClick(form, true);
    return form?.prepend(errorBlock);
}

const clearWebsiteIdError = (input: HTMLInputElement) => {
    const errorBlock = input.parentElement?.parentElement?.getElementsByClassName('error-block');

   if (errorBlock) {
       while (errorBlock.length > 0) {
           errorBlock[0].parentElement?.parentElement?.classList.remove('has-error');
           errorBlock[0].parentElement?.removeChild(errorBlock[0]);
       }
   }
}

export const clearPreviousErrors = () => {
    const errorBlocks = document.getElementsByClassName('error-block');
    const alertBlocks = document.getElementsByClassName('alert');

    while (errorBlocks.length > 0) {
        errorBlocks[0].parentElement?.classList.remove('has-error');
        errorBlocks[0].parentElement?.removeChild(errorBlocks[0]);
    }

    while (alertBlocks.length > 0) {
        alertBlocks[0].parentElement?.removeChild(alertBlocks[0]);
    }
}

export const clearInputs = (form: HTMLFormElement|null, sectionId: string|null = null) => {
    if (form === null) {
        return;
    }

    let inputs: HTMLCollectionOf<HTMLInputElement>;
    let textAreas: HTMLCollectionOf<HTMLTextAreaElement>;

    if (sectionId) {
        const section = form.querySelector(`#${sectionId}`);
        if (section === null) {
            return;
        }

        inputs = section.getElementsByTagName('input');
        textAreas = section.getElementsByTagName('textarea');
    } else {
        inputs = form.getElementsByTagName('input');
        textAreas = form.getElementsByTagName('textarea');
    }

    Object.values(textAreas).forEach((textArea: HTMLTextAreaElement) => {
        textArea.value = '';
    });

    Object.values(inputs).forEach((input: HTMLInputElement) => {
        if (unclearableInputs.includes(input.id) || input.type === 'radio') {
            return;
        }

        if (input.type === 'checkbox') {
            input.checked = false;
            return;
        }

        input.value = '';
    });
}

export const clearErrorsOnClick = (form: HTMLFormElement|null, clearSingleInput = false) => {
    if (form === null) {
        return;
    }

    const inputs = form.getElementsByTagName('input');
    const textAreas = form.getElementsByTagName('textarea');

    if (clearSingleInput) {
        clearSingleInputError(inputs);
        clearSingleInputError(textAreas);
        return;
    }

    Object.values(inputs).forEach((input: HTMLInputElement) => {
        input.addEventListener('click', () => {
            clearPreviousErrors();
        })
    });

    Object.values(textAreas).forEach((textArea: HTMLTextAreaElement) => {
        textArea.addEventListener('click', () => {
            clearPreviousErrors();
        })
    });
}

const clearSingleInputError = (elements: HTMLCollectionOf<HTMLInputElement>|HTMLCollectionOf<HTMLTextAreaElement>,) => {
    Object.values(elements).forEach((element: HTMLInputElement|HTMLTextAreaElement) => {
        element.addEventListener('click', () => {
            let errorBlock = element.parentElement?.querySelector('.error-block');
            const alertBlocks = document.getElementsByClassName('alert');

            if (errorBlock === null) {
                errorBlock = element.parentElement?.parentElement?.querySelector('.error-block');
            }

            while (alertBlocks.length > 0) {
                alertBlocks[0].parentElement?.removeChild(alertBlocks[0]);
            }

            element.parentElement?.classList.remove('has-error');
            element.parentElement?.parentElement?.classList.remove('has-error');

            if (errorBlock) {
                try {
                    element.parentElement?.removeChild(errorBlock);
                } catch (e) {
                    element.parentElement?.parentElement?.removeChild(errorBlock);
                }
            }
        });
    });
}

export const getRegistrationStatus = async (
    registrationType: string,
    data: ForTestingInputs|AcceptPaymentsInputs,
    form: HTMLFormElement|null,
    setLoading: (arg0: boolean) => void
) => {
    try {
        setLoading(true);
        const response = await getRegistrationResponse(registrationType, data);

        return handleRegistrationResponse(response);
    } catch (error: any) {
        form?.scrollIntoView();
        handleRegistrationErrors(error, form);

        return RegistrationStatusEnum.ERROR_FOUND;
    } finally {
        setLoading(false);
    }
}

export const handleRegistrationStatus = (
    registrationStatus: string|undefined,
    form: HTMLFormElement|null,
    captchaRef: RefObject<ReCAPTCHA>,
    navigate: (arg0: string) => void,
    registrationType: string
) => {
    if (registrationStatus === RegistrationStatusEnum.ERROR_FOUND) {
        return;
    }

    clearInputs(form);

    if (registrationType === 'testing') {
        fbq('track', 'Lead', {content_type: "testuoti"});
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'testing_submission',
            'formLocation': 'isidiegti_testavimui'
        });
    }

    if (registrationType === 'accept-payments') {
        fbq('track', 'Lead', {content_type: "isidiegti"});
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'long_form_submission',
            'formLocation': 'priimti_mokejimus'
        });
    }

    registrationStatus === RegistrationStatusEnum.VERIFICATION_NEEDED
        ? captchaRef.current?.reset()
        : navigate(`/${t('routes.data_received_thank_you')}`);
}

const handleRegistrationResponse = (response: any) => {
    clearPreviousErrors();

    if (response.data.response.result.developer_access_verification_needed === true) {
        callVerification(response);
        return RegistrationStatusEnum.VERIFICATION_NEEDED;
    }

    return RegistrationStatusEnum.SUCCESS;
}

const getRegistrationResponse = async (registrationType: string, data: ForTestingInputs|AcceptPaymentsInputs) => {
    if (registrationType === 'testing') {
        return sendForTestingForm(data as ForTestingInputs);
    }

    if (registrationType === 'accept-payments') {
        return sendAcceptPaymentsForm(data as AcceptPaymentsInputs);
    }
}
